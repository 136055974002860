import awa1Img from "../images/awards-licenses/awa1.png";
import awa2Img from "../images/awards-licenses/awa2.png";
import awa3Img from "../images/awards-licenses/awa3.png";
import lic1Img from "../images/awards-licenses/lic1.png";
import lic2Img from "../images/awards-licenses/lic2-new.svg";
import Responsible1Img from "../images/awards-licenses/responsible-gambling.svg";
import Responsible2Img from "../images/awards-licenses/responsible-gambling-2.svg";

function AwardsLicenses() {

    return (
        <div className="awards-licenses">
            <div className="awards">
                <h2>Awards</h2>
                <a href="https://news.roobet.com/all-posts/3-x-award-winning-roobet-is-king-of-the-world-at-the-migea-maltas-gaming-excellence-awards">
                    <img src={awa1Img} alt="awards" />
                </a>
                <a href="https://news.roobet.com/all-posts/3-x-award-winning-roobet-is-king-of-the-world-at-the-migea-maltas-gaming-excellence-awards">
                    <img src={awa2Img} alt="awards" />
                </a>
                <a href="https://news.roobet.com/all-posts/3-x-award-winning-roobet-is-king-of-the-world-at-the-migea-maltas-gaming-excellence-awards">
                    <img src={awa3Img} alt="awards" />
                </a>
            </div>

            <div className="licenses">
                <h2>Licenses</h2>
                <a href="/">
                    <img src={lic1Img} alt="licenses" />
                </a>
                <a href="/">
                    <img src={lic2Img} alt="licenses" />
                </a>
            </div>

            <div className="responsible-gambling">
                <h2>Responsible Gambling</h2>
                <a href="https://www.gambleaware.org/">
                    <img src={Responsible1Img} alt="licenses" />
                </a>
                <a href="https://www.gambleaware.org/">
                    <img src={Responsible2Img} alt="licenses" />
                </a>
            </div>
        </div >
    )
}

export default AwardsLicenses;