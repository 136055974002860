import { useState } from 'react';

// SVG
import { ReactComponent as VisaSvg } from '../svg/visa.svg';
import { ReactComponent as GpaySvg } from '../svg/gpay.svg';
import { ReactComponent as ApplePaySvg } from '../svg/apple-pay.svg';
import { ReactComponent as MasterCardSvg } from '../svg/master-card.svg';
import { ReactComponent as ChevronDownSvg } from '../svg/chevron-down.svg';
import { ReactComponent as ActiveSvg } from '../svg/active.svg';


// Coins SVG
import { ReactComponent as BitcoinSvg } from '../svg/currencies/bitcoin.svg';
import { ReactComponent as UsdtSvg } from '../svg/currencies/usdt.svg';
import { ReactComponent as UsdcSvg } from '../svg/currencies/usdc.svg';
import { ReactComponent as EthSvg } from '../svg/currencies/eth.svg';
import { ReactComponent as XrpSvg } from '../svg/currencies/xrp.svg';
import { ReactComponent as TrxSvg } from '../svg/currencies/trx.svg';
import { ReactComponent as LtcSvg } from '../svg/currencies/ltc.svg';
import { ReactComponent as DogeSvg } from '../svg/currencies/doge.svg';

function CashierBuy() {
    // wallet address:
    const BTC = "bc1q6j298m7gcf0mut8c9yxceej8u2fl550xwj8xdl"
    const ERC = "0x66728448bfa74718bBf760838B6C91D6eA1ed6f8"
    const LTC = "ltc1q3h6uup5ups4rt29tdmnnlfk43kh29x4nv7eldd"
    const XRP = "r3yPBggXZ5SbMsokNQgiW2wANVzouCukK2"
    const DOGE = "DEc9Ypn9T6Ea9mVo9mGhGmm5pZ5vG9euQc"
    const TRX = "TMePpyebjZczVqhkivgXiyaV2WVvaBzaDp"

    const [buyValue, setBuyValue] = useState(100);
    const [isBtnDisabled, setBtnDisabled] = useState(false);
    const [isSelectDropDownOpened, setIsSelectDropDownOpened] = useState(false);
    const [selectedCoin, setSelectedCoin] = useState({
        coin: "Bitcoin",
        short: "BTC",
        svg: <BitcoinSvg className="svg-icon" />,
        address: BTC
    });

    const handleInputChange = (event) => {
        setBuyValue(event.target.value);
        if (event.target.value < 100) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    };

    const handleCoinSelect = (coin) => {
        setIsSelectDropDownOpened(false);
        setSelectedCoin(coin);
    };

    const handleBuy = () => {
        let currencyCode
        switch (selectedCoin.short) {
            case "BTC":
                currencyCode = "btc"
                break
            case "USDT":
                currencyCode = "usdt"
                break
            case "USDC":
                currencyCode = "usdc"
                break
            case "ETH":
                currencyCode = "eth"
                break
            case "XRP":
                currencyCode = "xrp"
                break
            case "TRX":
                currencyCode = "trx"
                break
            case "LTC":
                currencyCode = "ltc"
                break
            case "DOGE":
                currencyCode = "doge"
                break
            default:
                currencyCode = "btc"
        }

        const url = `https://buy.moonpay.com/?apiKey=pk_live_h6l9zQQ4B58G2kYSnaoU9qkbzOMEH0&walletAddress=${selectedCoin.address}&currencyCode=${currencyCode}&baseCurrencyAmount=${buyValue}&baseCurrencyCode=usd&redirectURL=https%3A%2F%2Froobet.com&unsupportedRegionRedirectUrl=https%3A%2F%2Froobet.com`
        window.open(url, "_blank");
    };

    const coinData = [
        { short: "BTC", coin: "Bitcoin", svg: <BitcoinSvg className="svg-icon" />, address: BTC },
        { short: "USDT", coin: "Tether", svg: <UsdtSvg className="svg-icon" />, address: ERC },
        { short: "USDC", coin: "USDC", svg: <UsdcSvg className="svg-icon" />, address: ERC },
        { short: "ETH", coin: "Ethereum", svg: <EthSvg className="svg-icon" />, address: ERC },
        { short: "XRP", coin: "Ripple", svg: <XrpSvg className="svg-icon" />, address: XRP },
        { short: "TRX", coin: "TRON", svg: <TrxSvg className="svg-icon" />, address: TRX },
        { short: "LTC", coin: "Litecoin", svg: <LtcSvg className="svg-icon" />, address: LTC },
        { short: "DOGE", coin: "Dogecoin", svg: <DogeSvg className="svg-icon" />, address: DOGE },
    ];

    return (
        <>
            <div className="cashier-buy">
                <div className="header">
                    <div className="header-title">
                        Don't Have Crypto?
                    </div>
                    <div className="header-subtitle">
                        Complete the simple checkout process and funds will automatically be transferred to your selected Roobet wallet.
                    </div>
                </div>
                <div className="chashier-buy-main">
                    <div className="chashier-buy-select" onClick={() => setIsSelectDropDownOpened(!isSelectDropDownOpened)}>
                        <div>
                            <div className="chashier-buy-select-svg">
                                {selectedCoin.svg}
                            </div>
                            <div className="chashier-buy-select-title">
                                {selectedCoin.coin} <span>{selectedCoin.short}</span>
                            </div>
                        </div>
                        <div className={isSelectDropDownOpened ? "chevron-active" : ""}>
                            <ChevronDownSvg className="svg-icon" />
                        </div>
                        {isSelectDropDownOpened && (
                            <div className='coin-select-options-container'>
                                {coinData.map((coin, index) => (
                                    <div
                                        key={coin.short}
                                        className={`coin-select-option${selectedCoin.short === coin.short ? ' active' : ''}${index === 0 ? ' first' : ''}${index === coinData.length - 1 ? ' last' : ''}`}
                                        onClick={() => handleCoinSelect(coin)}
                                    >
                                        <div>
                                            <div className='coin-select-option-svg'>
                                                {coin.svg}
                                            </div>
                                            <div className='coin-select-option-title'>
                                                {coin.coin}
                                            </div>
                                        </div>
                                        {selectedCoin.short === coin.short &&
                                            <div className='active-svg'>
                                                <ActiveSvg className="svg-icon" />
                                            </div>}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <form>
                        <label>Purchase Amount</label>
                        <div className='input-container'>
                            <div>$</div>
                            <input
                                className="styled-input"
                                placeholder='100.00'
                                type='number'
                                value={buyValue}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>Minimum purchase amount $100.00</div>
                    </form>

                </div>
            </div >
            <div className="chashier-buy-footer">
                <button disabled={isBtnDisabled} className="styled-btn-primery" onClick={handleBuy}>Buy</button>
                <div className="buy-icons-container">
                    <div className="buy-icon">
                        <VisaSvg className="svg-icon" />
                    </div>
                    <div className="buy-icon">
                        <GpaySvg className="svg-icon" />
                    </div>
                    <div className="buy-icon">
                        <ApplePaySvg className="svg-icon" />
                    </div>
                    <div className="buy-icon">
                        <MasterCardSvg className="svg-icon" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CashierBuy;
